<template>
  <div class="box-container">
    <!--    <v-row
      v-if="!item.id && loading"
      justify="center"
      align="center"
      class="justify-center align-center"
    >
      <v-col
        class="text-center text&#45;&#45;center justify-center align-center"
        cols="12"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-col>
    </v-row>-->
    <div
      v-if="item.id"
      class="d-flex justify-space-between flex-wrap mb-5"
    >
      <div class="d-flex align-center ">
        <v-avatar
          tile
          size="25"
          class="me-3"
        >
          <img
            src="@/assets/images/icons/bag_filled.svg"
            alt=""
          >
        </v-avatar>
        <h2 class="mb-0">
          {{ $t('Votre colis') }} #{{ item.id || $route.params.id }} {{ }}
        </h2>
        <v-spacer />
      </div>
      <div class="d-flex ">
        <v-btn
          v-if="item.id && item.status >= $store.state.statuses.packageStatus.closed && user && user.id === item.seller_id && item.seller.is_pro_seller"
          outlined
          class=" text-capitalize font-600"
          color="default"
          target="_blank"
          :href="`${ baseUrl }/print/${item.id}`"
        >
          <v-icon>mdi-printer</v-icon>
          {{ $t('Facture') }}
        </v-btn>
      </div>
    </div>

    <v-row v-if="item.id">
      <v-col cols="12">
        <v-alert
          v-if="info.active"
          :type="info.type"
          prominent
          dismissible
        >
          {{ info.message }}
        </v-alert>
        <base-card>
          <div class="px-6 py-14">
            <div class="d-flex align-center">
              <div class="p-relative">
                <v-avatar
                  class="order-details-custom-done"
                  size="22"
                  color="grey lighten-2"
                >
                  <img
                    v-svg-inline
                    class="w-12 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/done.svg"
                    alt=""
                  >
                </v-avatar>
                <v-avatar
                  size="64"
                  color="primary"
                >
                  <img
                    v-svg-inline
                    class="w-32 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/package-box.svg"
                    alt=""
                  >
                </v-avatar>
              </div>
              <v-sheet
                :color="item.status >= $store.state.statuses.packageStatus.validated?'primary':'grey lighten-2'"
                elevation="0"
                height="4"
                class="flex-grow-1"
              />
              <div class="p-relative">
                <v-avatar
                  v-if="item.status >= $store.state.statuses.packageStatus.validated"
                  class="order-details-custom-done"
                  size="22"
                  color="grey lighten-2"
                >
                  <img
                    v-svg-inline
                    class="w-12 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/done.svg"
                    alt=""
                  >
                </v-avatar>
                <v-avatar
                  size="64"
                  :color="item.status >= $store.state.statuses.packageStatus.validated?'primary':'grey lighten-2'"
                >
                  <img
                    v-svg-inline
                    class="w-32 icon white--text mr-0"
                    :class="item.status >= $store.state.statuses.packageStatus.validated?'white--text':'primary--text'"
                    src="@/assets/images/icons/truck-1.svg"
                    alt=""
                  >
                </v-avatar>
              </div>
              <!--              :color="item.status >= $store.state.statuses.packageStatus.closed?'primary':'grey lighten-2'"-->
              <v-sheet
                :color="item.status >= $store.state.statuses.packageStatus.delivered?'primary':'grey lighten-2'"
                elevation="0"
                height="4"
                class="flex-grow-1"
              />
              <div class="p-relative">
                <!--                <v-avatar
                  v-if="item.status >= $store.state.statuses.packageStatus.closed"-->
                <v-avatar
                  v-if="item.status >= $store.state.statuses.packageStatus.delivered"

                  class="order-details-custom-done"
                  size="22"
                  color="grey lighten-2"
                >
                  <img
                    v-svg-inline
                    class="w-12 icon secondary--text text--darken-1 mr-0"
                    src="@/assets/images/icons/done.svg"
                    alt=""
                  >
                </v-avatar>
                <v-avatar
                  size="64"
                  class=""
                  :color="item.status >= $store.state.statuses.packageStatus.delivered?'primary':'grey lighten-2'"
                >
                  <img
                    v-svg-inline
                    :class="{'white--text': item.status >= $store.state.statuses.packageStatus.delivered, 'primary--text': item.status < $store.state.statuses.packageStatus.validated}"
                    class="w-32 icon white--text mr-0"
                    src="@/assets/images/icons/delivery-done.svg"
                    alt=""
                  >
                </v-avatar>
              </div>
            </div>
            <div class=" mt-10">
              <v-btn depressed outlined color="primary" class="mt-4" @click="openChat">{{ item.seller_id != user.id?$t("Contacter le vendeur"):$t("Contact Acheteur") }}</v-btn>
              <div
                v-if="item.status === $store.state.statuses.packageStatus.returnConfirmed"
                class="py-2 px-5 red--text red lighten-5 text-center text-wrap rounded-pill"
              >
                <p class="mb-2">
                  {{ $t("Le service planet market ce charge de la gestion de ce colis litigieux.") }}
                </p>
              </div>
              <div
                v-if="item.id && item.status === $store.state.statuses.packageStatus.cancelled && user.id === item.user_id"
                class="py-2 px-5 red--text red lighten-5 text-center text-wrap rounded-pill"
              >
                <p class="mb-2">
                  {{ $t('Votre Commande a bien été annulé vous ne serez pas débité de votre Carte bancaire.') }}
                </p>
              </div>
              <div
                v-if="item.status === -2 && item.litigation && item.litigation.order_item_id &&
                  (item.seller_id === user.id || item.user_id === user.id )"
                class="py-2 px-5 red--text red lighten-5 text-center text-wrap rounded-pill"
              >
                <p class="mb-2">
                  {{ item.litigation.subject }}
                </p>
              </div>
              <!--              <div
                              v-if="item.id && item.status != $store.state.statuses.packageStatus.cancelled && item.status != $store.state.statuses.packageStatus.cancelled"
                              class="py-2 px-5 red&#45;&#45;text red lighten-5 text-center text-wrap rounded-pill"
                            >
                              <p class="mb-2">
                                {{ $t("Votre facture sera disponible une fois que tous les colis auront été validé par les vendeurs. Merci")}}
                              </p>
                            </div>-->
              <!--              <template    v-if="item.id && item.status != $store.state.statuses.packageStatus.cancelled && item.status != $store.state.statuses.packageStatus.cancelled">
                              <div
                                class="py-2 my-2 px-5 mb-2 primary&#45;&#45;text primary lighten-5 text-center text-wrap rounded-pill"
                              >

                                <p class="mb-2">
                                  {{ $t('Certain colis de votre commande sont déjà en Transit. Pour annuler allez dans la gestion des colis')}} -
                                  <span class="font-weight-bold">{{ $t("Mes colis expédiés") }}</span>
                                </p>
                              </div>
                              <p class="mb-2"></p>
                              <cancel-order
                                :item="item"
                                @removed="getOrder"
                              />
                            </template>-->
            </div>
          </div>
        </base-card>
      </v-col>
      <v-col
        cols="12"
      >
        <base-card>
          <div
            class="grey lighten-2 d-flex justify-space-between flex-wrap"
          >
            <div class="d-flex my-3 mx-3">
              <p class="text-14 grey--text text--darken-2 mb-0 mr-2">
                {{ $t('Numéro de colis') }}:
              </p>
              <p class="mb-0 grey--text text--darken-4">
                {{ item.id }}
              </p>
            </div>
            <div
              v-if="item.user_id === user.id"
              class="d-flex my-3 mx-3"
            >
              <p class="text-14 grey--text text--darken-2 mb-0 mr-2">
                {{ $t('Vendeur') }}:
              </p>
              <router-link
                v-if="item.seller"
                :to="`/shops/${item.seller_id}`"
                class="mb-0 grey--text text--darken-4 text-decoration-none"
              >
                {{ item.seller.pseudo || '' }}
              </router-link>
            </div>
            <!--            <div class="d-flex my-3 mx-3">
              <p class="text-14 grey&#45;&#45;text text&#45;&#45;darken-2 mb-0 mr-2">
                {{ $t('Status') }}:
              </p>
              <p class="mb-0 grey&#45;&#45;text text&#45;&#45;darken-4">
                {{ ($store.state.statuses.packages.find((e) => e.status === item.status) || { message: '-' }).message }}
              </p>
            </div>-->
            <div
              v-if="item.status === $store.state.statuses.packageStatus.paid && item.user_id === user.id"
              class="d-flex my-3 mx-3"
            >
              <cancel-package
                :item="item"
                @removed="getOrder"
              />
            </div>
          </div>

          <div
            v-for="(orderItem, index) in item.items"
            :key="index"
          >
            <div
              class="d-flex align-center justify-space-between flex-wrap  pa-4"
            >
              <div class="d-flex align-center justify-space-between  me-4 mb-3 mb-md-0">
                <v-avatar
                  tile
                  size="64"
                  class="me-4"
                >
                  <img
                    :src="orderItem.product.image || '@/assets/images/products/Groceries/2.PremiumGroceryCollection.png'"
                    alt=""
                  >
                </v-avatar>
                <div class="">
                  <div class="text-14 font-600">
                    {{ orderItem.product_name }}
                  </div>
                  <div class="text-14 grey--text text--darken-2">
                    {{ orderItem.quantity }} x {{ orderItem.product.weight }}kg =
                    &euro;{{ orderItem.quantity * orderItem.product.weight }}  <span
                      class="float-right ml-4"
                    >Total:  &euro;{{ orderItem.total }}</span>
                  </div>
                  <p class="mb-0 grey--text text--darken-2">
                    <!--                {{ $t("Product properties") }}:<br>
                                    <span v-html="orderItem.product_attribute" />-->
                    <v-chip
                      v-if="orderItem.status < 0 && orderItem.litigation && (item.seller_id === user.id || item.user_id === user.id )"
                      x-small
                      color="warning"
                      class="me-2"
                      :title="orderItem.litigation?orderItem.litigation.subject:''"
                      @click=""
                    >
                      <v-avatar left>
                        <v-icon>mdi-alert</v-icon>
                      </v-avatar>
                      {{ $t('Gérer le litige') }}
                    </v-chip>
                    <v-chip
                      v-if="orderItem.rewind"
                      x-small
                      color="red"
                      dark
                    >
                      {{ $t('Remboursement') }}
                    </v-chip>
                    <v-chip
                      class=""
                      :color="`${($store.state.statuses.orderItem.find(e => e.status === orderItem.status) || {color: 'grey  lighten-2'}).color}`"
                      x-small
                    >
                      {{
                        ($store.state.statuses.orderItem.find(e => e.status === orderItem.status) || { message: orderItem.status }).message
                      }}
                    </v-chip>
                  </p>
                </div>

              </div>

              <v-btn-toggle
                v-if="item.seller_id === user.id && item.status === $store.state.statuses.packageStatus.paid || item.user_id === user.id && item.status === $store.state.statuses.packageStatus.paid ||
                  ((item.items && item.items.length > 1) && orderItem.quantity > 0 && orderItem.status === 3 && item.user_id === user.id && orderItem.litigation) ||
                  orderItem.status < 0 && orderItem.litigation && (item.seller_id === user.id || item.user_id === user.id )"
                v-model="icon"
                dense
                dark
                background-color="primary"
                small
              >
                <validate-order-item
                  v-if="item.seller_id === user.id && item.status === $store.state.statuses.packageStatus.paid && $vuetify.breakpoint.lgAndUp"
                  :item="orderItem"
                  @saved="getOrder"
                />
                <cancel-article
                  v-else-if="item.user_id === user.id && item.status === $store.state.statuses.packageStatus.paid && $vuetify.breakpoint.lgAndUp"
                  :item="orderItem"
                  @saved="getOrder"
                />
                <!--                <v-btn value="right" color="primary" class="d-md-block d-none">
                                  <span class="hidden-sm-and-down">{{ $t("Disponible") }}</span>
                                </v-btn>-->
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      value="justify"
                      v-bind="attrs"
                      color="primary"
                      :small="$vuetify.breakpoint.mdAndDown"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!--                    <validate-order-item
                      :is-btn="false"
                      v-if="item.seller_id === user.id && item.status === $store.state.statuses.packageStatus.paid" -->

                    <validate-order-item
                      v-if="item.seller_id === user.id && item.status === $store.state.statuses.packageStatus.paid"
                      :is-btn="false"
                      :item="orderItem"
                      @saved="getOrder"
                    />
                    <cancel-article
                      v-if="item.user_id === user.id && item.status === $store.state.statuses.packageStatus.paid"
                      :is-btn="false"
                      :item="orderItem"
                      @saved="getOrder"
                    />
                    <!--                    <v-list-item @click=""
                                                    >
                                          <v-list-item-title>{{ $t("Mon article a un soucis, Demarrer un litige") }}</v-list-item-title>-->
                    <create-dispute
                      v-if="((item.items && item.items.length > 1) && orderItem.quantity > 0 && orderItem.status === 3 && item.user_id === user.id && orderItem.litigation)"
                      :item="orderItem"
                    />
                    <template
                      v-if="orderItem.status < 0 && orderItem.litigation && (item.seller_id === user.id || item.user_id === user.id )"
                    >
                      <v-list-item :to="`/litigation/${orderItem.litigation }`">
                        <v-list-item-title>{{ $t("Gérer le litige") }}</v-list-item-title>
                      </v-list-item>
                    </template>

                    <!--                    <v-list-item v-if="user.id === orderItem.user_id" :to="`/products/${orderItem.product.slug }`">
                      <v-list-item-title>{{ $t("Rédiger un avis") }}</v-list-item-title>
                    </v-list-item>-->
                  </v-list>
                </v-menu>
              </v-btn-toggle>

            </div>
          </div>
<!--          <div
            class="grey lighten-2 d-flex justify-space-between flex-wrap"
          >
            <div class="d-flex my-0 pb-0 col-12 col-md-auto justify-space-between">
              <p class="text-14 grey&#45;&#45;text text&#45;&#45;darken-2 mb-0 mr-2">
                {{ $t('Sous-total') }}:
              </p>
              <p class="mb-0 font-weight-bold">
                &euro;{{ item.sub_total }}
              </p>
            </div>
            <div class="d-flex my-0 pb-0 col-12 col-md-auto justify-space-between">
              <p class="text-14 grey&#45;&#45;text text&#45;&#45;darken-2 mb-0 mr-2">
                {{ $t('Frais de transport') }}:
              </p>
              <p class="mb-0 font-weight-bold">
                &euro;{{ item.transporter_fee }}
              </p>
            </div>
            <div class="d-flex my-0 col-12 col-md-auto justify-space-between">
              <p class="text-14 grey&#45;&#45;text text&#45;&#45;darken-2 mb-0 mr-2">
                {{ $t('Total') }}:
              </p>
              <p class="mb-0 font-weight-bold">
                &euro;{{ item.total }}
              </p>
            </div>
          </div>-->
        </base-card>
      </v-col>
      <!--      <v-col cols="12" v-if="item.status === $store.state.statuses.packageStatus.delivered">-->

      <v-col
        v-if="item.address"
        cols="12"
        lg="12"
        xl="6"
      >
        <base-card>
          <div class="pa-5">
            <h4 class="mb-3 grey--text text--darken-4">
              {{ $t('Adresse de livraison') }}
            </h4>
            <p class="text-14 mb-0">
              <!--              {{ item.address ?item.address.address :'' }}, {{ item.address.city }} {{ item.address.zip }}-->
              <span class="mr-1">{{ item.address.address }},</span>
              <span class="mr-1">{{ item.address.city }},</span>
              <span class="me-1">{{ item.address.country_code }}</span>
              <span class="me-2"></span>
              <span class="me-1">{{ item.address.zip }}</span>
            </p>
            <div class="d-flex justify-space-between mb-2 mt-7">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t('Status') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                <v-chip :class="($store.state.statuses.packages.find((e) => e.status === item.status) || { message: '-' }).color">
                {{ ($store.state.statuses.packages.find((e) => e.status === item.status) || { message: '-' }).message }}
                </v-chip>
              </p>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t('Transporteur') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                {{ item.transporter ? item.transporter.name : '' }}  {{ item.transporter ? item.relay_point : '' }}

              </p>
            </div>
            <div v-if="item.shipping" class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t('Numéro d\'expédition') }}:
              </p>
              <p class="text-14 mb-0 font-weight-bold">
                <span v-if="item.shipping">{{ item.shipping.ExpeditionNum }}</span>
                <small v-if="item.transporter_url_follow && item.transporter_package_number"><a
                  target="_blank"
                  :href="`${item.transporter_url_follow}`"
                >{{ `${item.transporter_url_follow}` }}</a></small>
              </p>
            </div>
          </div>
        </base-card>
      </v-col>
      <v-col
        cols="12"
        lg="12"
        xl="6"
      >
        <base-card>
          <div class="pa-5">
            <h4 class="mb-3 grey--text text--darken-4">
              {{ $t('Résumé total') }}
            </h4>
            <div v-if="item.user_id == user.id" class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t('Sous - total') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ (item.sub_total).toFixed(2) }}
              </p>
            </div>
            <div
              v-if="item.donate && item.user_id == user.id"
              class="d-flex justify-space-between mb-2"
            >
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t('Don') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ (item.danate || 0).toFixed(2) }}
              </p>
            </div>
            <div v-if="item.user_id == user.id" class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t('Frais de Sécurité') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ (item.security_fee || 0).toFixed(2) }}
              </p>
            </div>
            <div v-if="item.user_id == user.id" class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                {{ $t('Frais de transport') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ (item.transporter_fee || 0).toFixed(2) }}
              </p>
            </div>
            <!--            <div class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 grey--text text--darken-2">
                Discount:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                -$30
              </p>
            </div>-->
            <v-divider class="my-3" />
            <div  class="d-flex justify-space-between mb-2">
              <p class="tex-14 mb-0 font-weight-bold">
                {{ $t('Total') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ item.user_id == user.id ?item.total:(item.sub_total).toFixed(2) }}
              </p>
            </div>
            <div
              v-if="item.rewind"
              class="d-flex justify-space-between mb-2"
            >
              <p class="tex-14 mb-0 font-weight-bold">
                {{ $t('Remboursement') }}:
              </p>
              <p class="tex-14 mb-0 font-weight-bold">
                &euro;{{ item.rewind }}
              </p>
            </div>
            <div
              v-if="item.status === $store.state.statuses.packageStatus.paid"
              class="d-flex justify-space-between flex-md-row flex-column mb-2 gap-2"
            >
              <cancel-package
                v-if="item.user_id === user.id || item.seller_id === user.id"
                :item="item"
                @saved="getOrder"
              />
              <validate-package
                v-if="item.seller_id === user.id"
                :item="item"
                @saved="getOrder"
              />
            </div>
            <div
              v-if="item.status === $store.state.statuses.packageStatus.validated && item.seller_id === user.id"
              class="d-flex justify-space-between mb-2"
            >
              <notify-relay
                :item="item"
                @saved="getOrder"
              />
            </div>
            <div
              v-if="item.status === $store.state.statuses.packageStatus.processing && item.user_id === user.id"
              class="d-flex justify-space-between mb-2"
            >
              <confirm-reception
                :item="item"
                @saved="getOrder"
              />
            </div>
            <div
              v-if="item.status === $store.state.statuses.packageStatus.processing && item.user_id === user.id && item.enable_litigation"
              class="d-flex justify-space-between mb-2"
            >
              <create-package-dispute
                :item="item"
                @saved="getOrder"
              />
            </div>
            <div
              v-if="item.status < 0 && item.litigation && !item.litigation.order_item_id"
              class="d-flex justify-space-between mb-2"
            >
              <create-package-dispute
                :item="item"
                @saved="getOrder"
              />
              <validate-return
                v-if="item.seller_id === user.id"
                :item="item"
                @saved="getOrder"
              />
            </div>
            <!--            <p>{{ $t("Payment method") }}: Carte de crédit/débit</p>-->


          </div>
        </base-card>
      </v-col>
      <v-col
        v-if="item.shipping"
        cols="12"
      >
        <v-card class="title-shoping-cart">
          <v-card-title>
            {{ $t("Bordereau d'expédition détaillé") }}
            <v-spacer />
            <!--        <template v-if="item.seller_id === user.id">-->
            <template>
              <!--          <v-btn text v-if="item.shipping" :href="item.shipping.download_url" target="_blank"-->
              <v-btn
                color="primary"
                target="_blank"
                :href="item.shipping.download_url"
                style="margin-left: 3px"
              >
                <v-icon>mdi-download</v-icon>{{ $t("Imprimmer PDF") }}
              </v-btn>
            </template>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        v-if="item.status === 4"
        cols="12"
      >
        <review-form :pkg="item" />
      </v-col>
    </v-row>
    <overlay v-if="loading" />
  </div>
</template>

<script>

  import CancelPackage from '../../components/products/CancelPackage.vue'
  import CancelOrder from '../../components/products/CancelOrder.vue'
  import { mapGetters, mapActions, mapState, mapMutations  } from 'vuex'
  import ValidateOrderItem from '../../components/products/ValidateOrderItem.vue'
  import CancelArticle from '../../components/products/CancelArticle.vue'
  import CreateDispute from '../../components/products/CreateDispute.vue'
  import ReviewForm from '../../components/products/ReviewForm.vue'
  import ValidateReturn from '../../components/products/ValidateReturn.vue'
  import ValidatePackage from '../../components/products/ValidatePackage.vue'
  import NotifyRelay from '../../components/products/NotifyRelay.vue'
  import ConfirmReception from '../../components/products/ConfirmReception.vue'
  import CreatePackageDispute from '../../components/products/CreatePackageDispute.vue'
  import Overlay from '../../components/Overlay.vue'

  export default {
    components: {
      Overlay,
      CreatePackageDispute,
      ConfirmReception,
      NotifyRelay,
      ValidatePackage,
      ValidateReturn,
      ReviewForm,
      CreateDispute,
      CancelArticle,
      ValidateOrderItem,
      CancelOrder,
      CancelPackage,

    },
    data () {
      return {
        icon: false,
        isSidebar: false,
        loading: false,
        baseUrl: process.env.VUE_APP_API || process.env.baseURL || '',
        item: {},
        info: {
          active: false,
          message: '',
          type: 'danger',
        },
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        displayNotification: 'display_notification',
      }),
      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.amount * product.qty
        })
        return total
      },
      backButtonLabel () {
        return this.$t('Précédent')
      },
      nextButtonLabel () {
        if (this.step === 2) {
          return this.$t('Procéder au paiement')
        }
        return this.$t('Suivant')
      },
      finishButtonLabel () {
        return this.$t('Payer')
      },
    },
    created () {
      this.getOrder()
    },
    methods: {
      ...mapActions(['pushItem']),
      ...mapMutations([
        'updateChat', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      openChat() {
        if(this.user && this.user.id != this.item.seller_id){
          this.updateChat({type: 'package', typeId: this.item.id, title: "Colis #" + this.item.id, image: this.item.seller.avatar,})
        }else{
          this.updateChat({type: 'package', typeId: this.item.id, title: "Colis #" + this.item.id, image: this.item.user.avatar,})
        }
      },
      getOrder () {
        this.loading = true
        axios.get(`/packages/${this.$route.params.id}`).then((response) => {
          this.loading = false
          if (response.error || response.data.error) {

          } else {
            this.item = response.data
          }
        }).then(() => {
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="postcss" scoped>
.w-32 {
  width: 32px;
}

.w-12 {
  width: 12px;
}

.order-details-custom-done {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
